.navbar {
  height: 80px;
  font-family: 'Satoshi', sans-serif;
  background-color: #fff;
}
.navbar-brand {
  font-weight: 800;
  font-size: 24px;
}
.nav-link {
  font-size: 18px;
  font-weight: 500;
}
.main-container {
  font-family: 'Satoshi', sans-serif;
  min-height: calc(100vh - 150px);
}
.landing-header {
  font-weight: 800 !important;
}
.landing-sub-header {
  font-weight: 400;
}
.navbar-collapse {
  background-color: #fff;
}
.show {
  padding-bottom: 20px;
}
.img-marketing {
  background-image: url('../assets/mktg.webp');
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding-top: 110%;
}
.coming-soon-block {
  margin-top: 100px;
}

.img-onsite {
  background-image: url('../assets/onsite.webp');
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  padding-top: 110%;
}

.overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 1rem;
  box-sizing: border-box;
  color: white;
  background-color: rgba(13, 6, 33, 0.647);
  border-radius: 10px;
}

.btn-cta {
  background-color: #9600ff;
  color: #fff;
  font-weight: 500;
  width: 100%;
  margin-bottom: 20px;
}
.btn-cta:hover {
  background-color: #9600ff;
  color: #fff;
}

.form-control:focus {
  box-shadow: none !important;
}

.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(25%, 0, 0);
  transform: translate3d(25%, 0, 0);
}
.modal-header {
  border-bottom: none;
  font-weight: 500;
  font-family: 'Satoshi', sans-serif;
}

.sub-section-heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

.brew-top {
  font-family: 'Satoshi', sans-serif;
  font-weight: 800;
}

.cta-section {
  min-height: calc(100vh - 50px);
}

.sub-section-sub-heading {
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.product-suite {
  margin: 60px 0px 40px 0px;
}

.card {
  margin-bottom: 30px;
}

.underline-svg {
  position: relative;
  display: inline-block;
}

.underline-svg img {
  position: absolute;
  left: 0;
  bottom: -8px; /* Adjust this value to control the distance between the text and the underline */
  width: 100%;
  height: auto;
  pointer-events: none; /* This prevents the SVG from blocking mouse events on the text */
}
.explore-btn {
  text-decoration: none !important;
  color: rgb(1, 0, 0) !important;
}
.explore-btn:hover {
  text-decoration: none !important;
  color: #9600ff !important;
}
.feature-heading {
  font-family: 'satoshi', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.material-symbols-outlined {
  padding: 10px;
  background-color: #00b60c27;
  border-radius: 5px;
}
.feat-desc {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  margin: 20px 0px 0px 0px;
  color: rgb(105, 105, 105);
}
.feat-card {
  min-height: 280px;
}
.waitlist {
  margin-top: 50px;
  margin-bottom: 50px;
  border-radius: 10px;
  height: 400px;
  width: 100%;
  background-color: #000000;
  padding: 30px 30px 30px 30px;
}

.capture-main-text {
  font-family: 'Satoshi', sans-serif;
  color: #ffffff;
  font-weight: 700;
  font-size: 26px;
}
.img-hello {
  margin-top: 30px;
  margin-bottom: 30px;
}
.capture-sub-text {
  font-family: 'Poppins', sans-serif;
  color: rgb(214, 214, 214);
  font-size: 16px;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .mobile-margin-top {
    margin-top: 40px;
  }
  .waitlist {
    height: 600px;
  }
  .mobile-height-adder {
    height: 100px;
  }
}
